import { Spinner } from "../../components/Spinner";
import Button from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import {
  useLazyEnviarFullQuery,
  useLazyGenerarEmisionQuery,
  useVerArchivosEmisionQuery,
} from "../../services/config.service";
import { useLazyGetPendientesFullQuery } from "../../services/docs";
import { useState } from "react";
import { toastr } from "react-redux-toastr";

export const VerEmisionesScreen = () => {
  const { data: archivos, isLoading: loadingArchivos } =
    useVerArchivosEmisionQuery();
  const [generarAction, { data, isLoading: loadingGenerar }] =
    useLazyGenerarEmisionQuery();
  const [pendientesFullAction, { data: dataFull, isLoading: loadingFull }] =
    useLazyGetPendientesFullQuery();
  const [
    enviarFullAction,
    { data: dataEnvioFull, isLoading: loadingEnvioFull },
  ] = useLazyEnviarFullQuery();
  const [cargando, setCargando] = useState(false);

  const generar = async () => {
    setCargando(true);
    const g = await generarAction().unwrap();
    if (g) {
      setCargando(false);
      toastr.info("Archivos procesados");
    }
  };
  const pendientesFull = async () => {
    pendientesFullAction();
  };
  const enviarFull = async () => {
    enviarFullAction();
  };
  return (
    <>
      <div className="card">
        <div className="w-full">
          <div className="flex">
            <div className="flex-1">
              <h1 className="text-2xl">Ver Emisión</h1>
            </div>
            <div>
              <h1 className="text-2xl">
                <Input type="date" value="2024-05-31" />
              </h1>
            </div>
          </div>
          <hr className="my-2" />
        </div>
        <div className="mt-10 w-full ">
          <div className="block w-full overflow-x-auto">
            <table className="w-full border-collapse items-center bg-transparent ">
              <thead>
                <tr>
                  <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                    Nombre Archivo
                  </th>
                  <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                    Registros
                  </th>
                  <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                    Rechazados
                  </th>
                  <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                    Emitidos
                  </th>
                  <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                    Producto
                  </th>
                  <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                    Inclusión
                  </th>
                </tr>
              </thead>
              <tbody className="dark:divide-gray-199 divide-y divide-gray-200">
                <tr
                  key={1}
                  className="cursor-pointer border-b-secondary-ultra-light"
                >
                  <th className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-2 px-2 text-left align-middle text-xs ">
                    <span className="flex flex-col">
                      VISA_Digital_Travel4_240528.txt
                    </span>
                  </th>
                  <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 text-xs">
                    <span className="flex flex-col">17</span>
                  </td>
                  <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-xs">
                    <span className="flex flex-col">0</span>
                  </td>
                  <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-xs">
                    <span className="flex flex-col">17</span>
                  </td>
                  <td className="whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 align-middle text-xs">
                    138
                  </td>
                  <td className="whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 align-middle text-xs">
                    2024-05-31
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
