import { useSelector } from "react-redux";
import { RootState } from "../store";

export const ItemsMenuPermisos = () => {
  const { usuario } = useSelector((state: RootState) => state.ui);
  let items = [];
  const ejecutivo = [
    {
      estado: "activo",
      href: "/home/validaciones",
      icono: "campana",
      label: "Validar Documentos",
    },
    {
      estado: "activo",
      href: "/home/mis-pendientes",
      icono: "campana",
      label: "Mis Pendientes",
    },
    {
      estado: "activo",
      href: "/home/nuevo-tramite",
      icono: "circulo",
      label: "Nuevo Tramite",
    },
    {
      estado: "activo",
      href: "/home/buscar",
      icono: "circulo",
      label: "Buscar Tramite",
    },
    {
      estado: "activo",
      href: "/home/wakaway",
      icono: "calendario",
      label: "Wakaway",
    },
    {
      estado: "activo",
      href: "/home/reportes",
      icono: "calendario",
      label: "Reportes",
    },
  ];
  items = ejecutivo;
  if (usuario.fi_perfil_id === 1) {
    items = [
      ...ejecutivo,
      {
        estado: "activo",
        href: "/home/asignar-tramites",
        icono: "campana",
        label: "Asignar Tramites",
      },
      {
        estado: "activo",
        href: "/home/agregar-especialista",
        icono: "calendario",
        label: "Agregar Especialista",
      },
    ];
  }
  return items;
};
