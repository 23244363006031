import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  useEliminarTramiteMutation,
  useGetEtapasTramiteQuery,
  useGetSiniestroQuery,
} from "../services/docs";
import { Spinner } from "../components/Spinner";
import { ItemDato } from "../components/ItemDato";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { cambiarEtapa } from "../features/ui/uiSlice";
import { RootState } from "../store";
import { elapsed, elapsedCerrado, fecha_actual } from "../hooks/elapsed";
import Button from "../components/ui/Button";
import { Label } from "../components/ui/Label";
import { useForm } from "../hooks/useForm";
import { Input } from "../components/ui/Input";
import { toastr } from "react-redux-toastr";

export const TramiteLayout = () => {
  const path = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const url = path.pathname.split("/");
  const tab = url[url.length - 1];
  const tabs = ["expediente", "contacto-cliente", "tiempos", "dictamenes"];
  let { siniestro, tramite_id } = useParams();
  const { data: etapa, isLoading } = useGetEtapasTramiteQuery({
    tramite: tramite_id,
    siniestro: siniestro,
  });
  const { etapaActual, fc_sub_etapa } = useSelector(
    (state: RootState) => state.ui
  );
  const [
    eliminarTramiteAction,
    { data: siniestros, isLoading: loadingEliminar, error },
  ] = useEliminarTramiteMutation();

  const { formulario, handleInputChange, handleSelectChange } = useForm({
    fi_rechazo: 0,
    fc_motivo_rechazo: "",
    fi_tramite_id: +tramite_id,
  });
  const [elimina, setElimina] = useState(false);
  useEffect(() => {
    if (etapa) {
      if (etapaActual === 0 && etapa.tramite.t_fi_etapa) {
        dispatch(
          cambiarEtapa({
            etapaActual: etapa.tramite.t_fi_etapa,
            fc_sub_etapa: etapa.tramite.fc_sub_etapa,
          })
        );
      }
    }
  }, [etapa]);
  const eliminarTramite = async () => {
    if (+formulario.fi_rechazo === 0) {
      toastr.error("Debe seleccionar un tipo de Eliminación");
      return;
    }
    if (formulario.fc_motivo_rechazo === "") {
      toastr.error("Debes de escribir una descripción del motivo");
      return;
    }
    const resp = await eliminarTramiteAction({
      ...formulario,
      fi_rechazo: +formulario.fi_rechazo,
      fi_tramite_id: +formulario.fi_tramite_id,
    }).unwrap();
    if (resp) {
      navigate("/view/siniestro/" + siniestro + "/" + tramite_id + "/");
      toastr.error(resp.message);
    }
  };
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div className="w-full rounded-t bg-primary text-center text-white">
        <span>{fc_sub_etapa}</span>
      </div>
      <div className="card w-full">
        <div className="grid grid-flow-row grid-cols-4">
          {elimina && (
            <>
              <div className="flex flex-col px-6">
                <Label htmlFor="fi_rechazo" className="my-2">
                  Motivo de rechazo:
                </Label>
                <select
                  className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                  name="fi_rechazo"
                  onChange={handleSelectChange}
                >
                  <option value="">Selecciona</option>
                  {etapa &&
                    etapa.rechazos &&
                    etapa.rechazos.map((b: any) => {
                      return (
                        <option key={b.id} value={b.id}>
                          {b.fc_sub_etapa}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="flex flex-col px-6">
                <Label htmlFor="fi_rechazo" className="m-2">
                  Descripción del rechazo:
                </Label>
                <Input
                  type="text"
                  name="fc_motivo_rechazo"
                  onChange={handleInputChange}
                />
              </div>
              <div></div>
              <div className="m-auto">
                <div className="flex">
                  {loadingEliminar && <Spinner />}
                  {!loadingEliminar && (
                    <>
                      <Button
                        onClick={eliminarTramite}
                        className="border border-primary bg-white"
                      >
                        <span className="text-primary">
                          Confirmar eliminación
                        </span>
                      </Button>
                      <Button
                        onClick={() => setElimina(!elimina)}
                        className="border border-primary bg-white"
                      >
                        <span className="text-primary">Cancelar</span>
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {!elimina && (
            <>
              <div></div>
              <div></div>
              <div></div>
              <Button
                onClick={() => setElimina(!elimina)}
                className="border border-primary bg-white"
              >
                <span className="text-primary">Eliminar Tramite</span>
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="card w-full">
        {etapa && etapa.tramite && (
          <>
            <div className="grid grid-cols-3 place-items-stretch gap-4">
              <div className="item flex flex-col">
                <span className="text-sm text-gray-600">
                  {etapa.tramite.s_fc_descripcion_aseguradora}
                </span>
                <span className="text-sm text-gray-600">
                  {etapa.tramite.t_fi_producto_id}
                  {" - "}
                  {etapa.tramite.s_fc_descripcion_producto}
                </span>
                <span className="text-sm text-gray-600">
                  {etapa.tramite.t_fi_cobertura_id}
                  {" - "}
                  {etapa.tramite.s_fc_descripcion_cobertura}
                </span>
                <span className="text-sm text-gray-600">
                  <span className="flex flex-col">
                    <b>Ultimo Analista: </b>
                    {etapa.tramite.eje_fc_nombres +
                      " " +
                      etapa.tramite.eje_fc_apellido_paterno +
                      " " +
                      etapa.tramite.eje_fc_apellido_materno}
                  </span>
                </span>
              </div>
              <div className="item flex flex-col">
                <span className="text-sm text-gray-600">
                  {etapa.tramite.s_fc_poliza_aseguradora}
                </span>
                <span className="text-sm text-gray-600">
                  {etapa.tramite.t_fc_nombre_asegurado}
                </span>
                <span className="text-sm text-gray-600">
                  <b>Monto: </b>
                  {etapa.tramite.t_fn_monto_reclamado +
                    " " +
                    etapa.tramite.s_fc_iso_moneda}
                </span>
                <span className="text-sm text-gray-600">
                  <span className="flex flex-col">
                    <b>Ultima Etapa: </b>
                    {etapa.tramite.t_fi_etapa === 7
                      ? elapsedCerrado(
                          etapa.tramite.t_fd_registro_tramite,
                          etapa.tramite.sem_fd_fecha_inicial
                        )
                      : elapsed(etapa.tramite.sem_fd_fecha_inicial)}
                  </span>
                </span>
              </div>
              <div className="item flex flex-col">
                <span className="text-sm text-gray-600">
                  <b>Correo: </b>
                  {etapa.tramite.s_fc_correo_contacto}
                </span>
                <span className="text-sm text-gray-600">
                  <b>Fecha Siniestro: </b>
                  {fecha_actual(etapa.tramite.t_fd_fecha_siniestro).replace(
                    "0:0:0",
                    ""
                  )}
                </span>
                <span className="text-sm text-gray-600">
                  <b>Fecha Registro: </b>
                  {fecha_actual(etapa.tramite.t_fd_registro_tramite).replace(
                    "0:0:0",
                    ""
                  )}
                </span>
                <span className="text-sm text-gray-600">
                  {etapa.tramite.t_id}
                  {" / "}
                  <b>{etapa.tramite.s_fc_siniestro}</b>
                </span>
                <span className="text-sm text-gray-600">
                  <span className="flex flex-col">
                    <b>Tiempo del tramite: </b>
                    {etapa.tramite.t_fi_etapa === 7
                      ? elapsedCerrado(
                          etapa.tramite.t_fd_registro_tramite,
                          etapa.tramite.sem_fd_fecha_inicial
                        )
                      : elapsed(etapa.tramite.t_fd_registro_tramite)}
                  </span>
                </span>
              </div>
            </div>
          </>
        )}

        <div className="my-4 w-full">
          <h3 className="mt-3 text-primary">Etapas:</h3>
          <div className="flex w-full items-center justify-between overflow-hidden rounded bg-primary-ultra-light text-center text-sm">
            {etapa &&
              etapa.etapas &&
              etapa.etapas.length > 0 &&
              etapa.etapas.map((e: any) => {
                return (
                  <div
                    className={
                      etapaActual === e.fi_etapa
                        ? "item bg-primary p-3 pl-3 text-white"
                        : "item p-3"
                    }
                    key={e.fi_etapa}
                  >
                    {e.fc_nombre_etapa}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="space-y-5">
          <div className="border-b border-b-gray-200">
            <ul className="-mb-px flex items-center gap-4 text-sm font-medium">
              <li className="flex-1">
                <Link
                  to={`/view/siniestro/${siniestro}/${tramite_id}/proceso`}
                  className={
                    !tabs.includes(tab)
                      ? "relative flex items-center justify-center gap-2 px-1 py-3 text-primary after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:bg-primary hover:text-primary"
                      : "relative flex items-center justify-center gap-2 px-1 py-3  after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full hover:text-primary"
                  }
                >
                  Proceso Actual
                </Link>
              </li>
              <li className="flex-1">
                <Link
                  to={`/view/siniestro/${siniestro}/${tramite_id}/expediente`}
                  className={
                    tab === tabs[0]
                      ? "relative flex items-center justify-center gap-2 px-1 py-3 text-primary after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:bg-primary hover:text-primary"
                      : "relative flex items-center justify-center gap-2 px-1 py-3  after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full hover:text-primary"
                  }
                >
                  Expediente
                </Link>
              </li>
              <li className="flex-1">
                <Link
                  to={`/view/siniestro/${siniestro}/${tramite_id}/contacto-cliente`}
                  className={
                    tab === tabs[1]
                      ? "relative flex items-center justify-center gap-2 px-1 py-3 text-primary after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:bg-primary hover:text-primary"
                      : "relative flex items-center justify-center gap-2 px-1 py-3  after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full hover:text-primary"
                  }
                >
                  Contacto Cliente
                </Link>
              </li>
              <li className="flex-1">
                <Link
                  to={`/view/siniestro/${siniestro}/${tramite_id}/tiempos`}
                  className={
                    tab === tabs[2]
                      ? "relative flex items-center justify-center gap-2 px-1 py-3 text-primary after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:bg-primary hover:text-primary"
                      : "relative flex items-center justify-center gap-2 px-1 py-3  after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full hover:text-primary"
                  }
                >
                  Tiempos
                </Link>
              </li>
              <li className="flex-1">
                <Link
                  to={`/view/siniestro/${siniestro}/${tramite_id}/dictamenes`}
                  className={
                    tab === tabs[3]
                      ? "relative flex items-center justify-center gap-2 px-1 py-3 text-primary after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:bg-primary hover:text-primary"
                      : "relative flex items-center justify-center gap-2 px-1 py-3  after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full hover:text-primary"
                  }
                >
                  Dictamentes
                </Link>
              </li>
            </ul>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
