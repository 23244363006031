import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { RootState } from "../store";
import { useEffect, useState } from "react";
import { useLazyValidarSesionQuery } from "../services/docs";
import {
  agregarToken,
  cambiaValida,
  iniciarSesion,
} from "../features/ui/uiSlice";
import { Spinner } from "@phosphor-icons/react";

export const AuthLayout = () => {
  const { token, usuario, valida } = useSelector(
    (state: RootState) => state.ui
  );
  const navigate = useNavigate();
  const [validaAction] = useLazyValidarSesionQuery();
  const [isLoading, setloading] = useState(false);
  const dispatch = useDispatch();
  if (!token) {
    return <Navigate to="/auth/login" replace />;
  }
  const validaSesion = async () => {
    try {
      dispatch(cambiaValida());
      const data = await validaAction().unwrap();
      if (!data) {
        dispatch(agregarToken(""));
        navigate("/auth/login", { replace: true });
      }
      if (data) {
        dispatch(iniciarSesion(data));
        setloading(false);
      }
    } catch (err) {
      console.log("error", err);
      dispatch(agregarToken(""));
      navigate("/auth/login", { replace: true });
    }
  };
  useEffect(() => {
    validaSesion();
  }, []);

  if (isLoading || valida) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return <Outlet />;
};
