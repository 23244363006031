import {
  useGetPendientesQuery,
  useLazyGetPendientesQuery,
  useRmEspecialistaMutation,
} from "../../../services/docs";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../components/Spinner";
import { elapsed, fecha_actual } from "../../../hooks/elapsed";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";
import { TablaTramitesComponent } from "../../../components/TablaTramitesComponent";

export const MisPendientesScreen = () => {
  const { data, isLoading } = useGetPendientesQuery();
  const [getPendientesAction, { isLoading: isLoadingL }] =
    useLazyGetPendientesQuery();
  const [rmEspecialistaAction, { isLoading: isLoadingE }] =
    useRmEspecialistaMutation();
  const [seleccion, setSeleccion] = useState(0);
  const { usuario } = useSelector((state: RootState) => state.ui);
  useEffect(() => {
    if (data) {
      if (data.pendientes.length > 0) {
        setTemp(data.pendientes);
        setPendientes(data.pendientes);
        setSeleccion(+data.fi_seleccion);
      }
    }
  }, [data]);
  const [temp, setTemp] = useState([]);
  const [pendientes, setPendientes] = useState([]);
  const navigate = useNavigate();
  const verMas = (p: any) => {
    navigate(`/view/siniestro/${p.t_fc_siniestro}/${p.t_id}/proceso`);
  };
  const getFiltro = (p: any, id: number) => {
    if (p.cantidad > 0) {
      if (p.id === 0) {
        setPendientes(temp);
        return;
      }
      let np = [];
      if (id) {
        np = temp.filter(
          (t: any) => t.t_fi_etapa === p.id && t.t_fi_sub_etapa === id
        );
        console.log(np);
      } else {
        np = temp.filter((t: any) => t.t_fi_etapa === p.id);
      }

      setPendientes(np);
    }
  };
  const cambiarSeleccion = async (e: any) => {
    setSeleccion(+e);
    const resp = await rmEspecialistaAction({
      fi_usuario_id: usuario.id,
      fi_seleccion: +e,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 404) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
    if (resp.message) {
      getPendientesAction();
      toastr.info("Cambio de usuario");
    }
  };
  return (
    <div className="card">
      <div className="space-y-6">
        <div className="mb-3 mt-5 w-full">
          <div className="flex items-center">
            <div className="w-3/4">
              <h6>Mis Tramites Pendientes</h6>
            </div>
          </div>
          <hr />
        </div>
        {isLoading ||
          isLoadingL ||
          (isLoadingE && (
            <div className="flex w-full justify-center">
              <Spinner />
            </div>
          ))}
        {data && data.pendientes.length === 0 && (
          <div className="my-4 rounded bg-danger py-2 text-center text-white">
            No tienes pendientes
          </div>
        )}
        {data && (
          <div className="grid w-full grid-cols-6 items-center space-y-1">
            <div
              className={
                seleccion === 0
                  ? "card b w-full bg-primary text-center text-xs text-white"
                  : "card b w-full cursor-pointer text-xs"
              }
              onClick={() => cambiarSeleccion(0)}
            >
              Todos
            </div>
            {!isLoading &&
              usuario &&
              usuario.fi_perfil_id === 1 &&
              data &&
              data.especialistas &&
              data.especialistas.length > 0 &&
              data.especialistas.map((e: any) => {
                return (
                  <div
                    onClick={() => cambiarSeleccion(e.fi_usuario_id)}
                    className={
                      seleccion === e.fi_usuario_id
                        ? "card grid w-full grid-cols-3 bg-primary p-0 text-white"
                        : "card grid w-full cursor-pointer grid-cols-3 p-0"
                    }
                    key={e.id}
                  >
                    <span className="col-span-1 text-center text-lg">
                      {e.cantidad}
                    </span>
                    <span className="col-span-2 text-left text-xs">
                      {e.fc_apellido_paterno} {e.fc_apellido_materno}{" "}
                      {e.fc_nombre}
                    </span>
                  </div>
                );
              })}
          </div>
        )}
        {data && data.pendientes.length > 0 && (
          <>
            <div className="block w-full overflow-x-auto">
              <div className="grid place-items-center border-gray-200 bg-white  text-center shadow-sm    md:grid-cols-6">
                <div className="col-span-6 grid w-full justify-between md:grid-cols-8">
                  {data.grupos.map((g: any) => {
                    return (
                      <div
                        key={g.id}
                        className={
                          g.id === 2
                            ? "col col-span-2 w-full text-white "
                            : "col col-span-1 w-full  text-white"
                        }
                      >
                        <div
                          onClick={() => getFiltro(g, null)}
                          className="card w-full cursor-pointer bg-primary text-center"
                        >
                          <div className="grid w-full grid-cols-1 items-center text-left">
                            <div className="w-full text-center">
                              <h2 className="text-lg font-medium tracking-tighter text-white lg:text-3xl">
                                {g.cantidad}
                              </h2>
                              <p className="flex w-full flex-col text-sm">
                                <span className="w-full text-white">
                                  {g.etapa}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        {g.sub_etapa &&
                          g.sub_etapa.length > 0 &&
                          g.sub_etapa.map((s: any) => {
                            return (
                              <div
                                key={s.id}
                                className="card mt-1 w-full cursor-pointer bg-primary-ultra-light p-0 text-center"
                                onClick={() => getFiltro(g, s.id)}
                              >
                                <span className="w-full text-gray-800">
                                  {s.cantidad} {"-"}
                                  {s.sub_etapa}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <TablaTramitesComponent
              data={pendientes}
              texto_accion="Ver Mas"
              accion={verMas}
              ejecutivos={[]}
              cargando={false}
            />
          </>
        )}
      </div>
    </div>
  );
};
