import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type { DocsList, Pendientes } from './types'
const baseUrl = import.meta.env.VITE_URL_CONFIG

// Define a service using a base URL and expected endpoints
export const configApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('app_token')
      if (token) {
        headers.set(`Authorization`, `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    verArchivosEmision: builder.query<any, void>({
      query: () =>
        `/zurich/ver-archivos`,
    }),
    generarEmision: builder.query<any, void>({
      query: () =>
        `/zurich/carga-archivos`,
    }),
    enviarFull: builder.query<any, void>({
      query: () =>
        `/poliza/canales-externos`,
    }),
   getTipoConsultaEmision: builder.mutation({
      query: (post) => ({
        body: post,
        method: 'post',
        url: '/emisiones/busqueda',
      }),
    }),
   ConsultaEmision: builder.mutation({
      query: (post) => ({
        body: post,
        method: 'post',
        url: '/emisiones/busqueda-emision',
      }),
    }),
  }),
  reducerPath: 'apiConfig',
})

export const {
  useVerArchivosEmisionQuery,
  useLazyGenerarEmisionQuery,
  useGetTipoConsultaEmisionMutation,
  useConsultaEmisionMutation,
  useLazyEnviarFullQuery,
} = configApi
