import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
interface usuario {
  id: number;
  fc_nombres: string;
  fc_apellido_paterno: string;
  fc_apellido_materno: string;
  email: string;
  telefono: string;
  tipo_usuario_id: number;
  platform: "";
  fi_perfil_id: number;
  area: string;
  rfc: null;
  aseguradora: string;
  no_cliente: string;
  username: string;
  fc_clave_asegurado: string;
  subscription_channel: string;
  numero_asegurado: string;
  fi_asignacion: number;
  fi_seleccion: number;
}
const usuarioVacio = {
  id: null,
  fc_nombres: "",
  fc_apellido_paterno: "",
  fc_apellido_materno: "",
  email: "",
  telefono: "",
  tipo_usuario_id: null,
  platform: null,
  profile: "",
  area: "",
  rfc: null,
  aseguradora: "",
  no_cliente: "",
  username: "",
  fc_clave_asegurado: "",
  subscription_channel: "",
  numero_asegurado: "",
  fi_asignacion: 0,
  fi_seleccion: 0,
  fi_perfil_id: 0,
};

export interface UiState {
  token: string;
  usuario: usuario;
  etapaActual: number;
  fc_sub_etapa: string;
  valida: boolean;
}

const initialState: UiState = {
  token:
    typeof window !== "undefined"
      ? localStorage.getItem("app_token")
        ? localStorage.getItem("app_token")
        : ""
      : "",
  usuario: usuarioVacio,
  etapaActual: 0,
  fc_sub_etapa: "prueba",
  valida: true,
};

export const uiSlice = createSlice({
  initialState,
  name: "ui",
  reducers: {
    decrement: (state) => {
      state.usuario.id -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.usuario.id += action.payload;
    },
    iniciarSesion: (state, action: PayloadAction<usuario>) => {
      state.usuario = action.payload;
      state.valida = false;
    },
    agregarToken: (state, action: PayloadAction<any>) => {
      console.log(action.payload);
      localStorage.setItem("app_token", action.payload);
      state.token = action.payload;
    },
    cambiarEtapa: (state, action: PayloadAction<any>) => {
      console.log("pruebaX", action.payload);
      state.etapaActual = action.payload.etapaActual;
      state.fc_sub_etapa = action.payload.fc_sub_etapa;
    },
    cambiaValida: (state, action: PayloadAction<number>) => {
      state.valida = true;
    },
  },
});

export const { iniciarSesion, cambiarEtapa, agregarToken, cambiaValida } =
  uiSlice.actions;

export default uiSlice.reducer;
