import Button from "./ui/Button";
import { elapsed, fecha_actual } from "../hooks/elapsed";
import { useState } from "react";
import { Spinner } from "./Spinner";

export interface TramitesProps {
  data: any;
  accion: any;
  texto_accion: string;
  ejecutivos: any;
  cargando: boolean;
}

export const TablaTramitesComponent = ({
  data,
  accion,
  texto_accion,
  ejecutivos = [],
  cargando = false,
}: TramitesProps) => {
  const [eje, setEje] = useState(0);
  return (
    <div className="block w-full overflow-x-auto">
      <table className="w-full border-collapse items-center bg-transparent ">
        <thead>
          <tr>
            <td className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs ">
              <b>FS</b> = Fecha Siniestro{" "}
            </td>
            <td className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs ">
              <b>FR</b> = Fecha Registro{" "}
            </td>
            <td className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs ">
              <b>FE</b> = Fecha Etapa{" "}
            </td>
            <td className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs ">
              <b>EA</b> = Etapa Actual{" "}
            </td>
            <td className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs ">
              <b>UM</b> = Ultimo Usuario que modifico{" "}
            </td>
            <td className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs ">
              <b>SE</b> = Sub Etapa{" "}
            </td>
            <td className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs ">
              <b>TR</b> = Tiempo Registro{" "}
            </td>
            <td className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs ">
              <b>TUE</b> = Tiempo Ultima Etapa{" "}
            </td>
            <td className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs ">
              <b>UA</b> = Usuario Asignado{" "}
            </td>
          </tr>
        </thead>
      </table>
      <table className="w-full border-collapse items-center bg-transparent ">
        <thead>
          <tr>
            <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
              Producto
            </th>
            <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
              Siniestro
            </th>
            <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
              Etapa
            </th>
            <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
              Tiempos
            </th>
            <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
              Ver Mas
            </th>
          </tr>
        </thead>
        <tbody className="dark:divide-gray-199 divide-y divide-gray-200">
          {data &&
            data.map((d: any) => {
              return (
                <tr
                  key={d.t_id + "-" + d.s_id + "-" + d.t_fi_cobertura_id}
                  className="border-b-secondary-ultra-light"
                >
                  <th className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-2 px-2 text-left align-middle text-xs ">
                    <span className="flex flex-col">
                      <span>
                        {d.t_fi_producto_id}
                        {" / "}
                        {d.s_fc_descripcion_producto}
                      </span>
                      <span>
                        {d.t_fi_cobertura_id}
                        {" / "}
                        {d.s_fc_descripcion_cobertura}
                      </span>
                      <span>{d.s_fc_poliza_aseguradora}</span>
                      <span>{d.t_fc_nombre_asegurado}</span>
                    </span>
                  </th>
                  <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 text-xs">
                    <span className="flex flex-col">
                      <span>
                        <b>
                          {d.t_id}
                          {" / "}
                          {d.t_fc_siniestro.trim()}
                        </b>
                      </span>
                      <span>
                        <b>UA: </b>
                        {d.t_fi_agente_id} {" - "} {d.asig_fc_nombres}{" "}
                        {d.asig_fc_apellido_paterno}
                      </span>
                      <span>
                        <b>FR: </b> {fecha_actual(d.t_fd_registro_tramite)}
                      </span>
                      <span>
                        <b>FS: </b>{" "}
                        {d.t_fd_fecha_siniestro.replace("T06:00:00.000Z", "")}
                      </span>
                    </span>
                  </td>
                  <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-xs">
                    <span className="flex flex-col">
                      <span>
                        <b>EA:</b> {d.t_fc_etapa}
                      </span>
                      <span>
                        <b>SE:</b> {d.fc_sub_etapa}
                      </span>
                      <span>
                        <b>FE: </b> {fecha_actual(d.sem_fd_fecha_inicial)}
                      </span>
                      <span>
                        <b>UM: </b>
                        {d.sem_fi_usuario} {" - "} {d.eje_fc_nombres}{" "}
                        {d.eje_fc_apellido_paterno}
                      </span>
                    </span>
                  </td>
                  <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-xs">
                    <span className="flex flex-col">
                      <span>
                        <b>TR: </b>
                        {elapsed(d.t_fd_registro_tramite)}
                      </span>
                      <span>
                        <b>TUE: </b>
                        {elapsed(d.sem_fd_fecha_inicial)}
                      </span>
                    </span>
                  </td>
                  <td className="whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 align-middle text-xs">
                    {texto_accion === "Asignar" && (
                      <>
                        <span className="w-full">
                          <select
                            className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                            name="fi_busqueda_id"
                            onChange={(e) => setEje(+e.target.value)}
                          >
                            <option value="">Selecciona</option>
                            {ejecutivos &&
                              ejecutivos.map((b: any) => {
                                return (
                                  <option key={b.id} value={b.fi_usuario_id}>
                                    {b.fc_nombre} {b.fc_apellido_paterno}
                                  </option>
                                );
                              })}
                          </select>
                        </span>
                        {cargando && <Spinner />}
                        {!cargando && (
                          <Button
                            onClick={() => {
                              accion(d, eje);
                            }}
                          >
                            {texto_accion}
                          </Button>
                        )}
                      </>
                    )}
                    {texto_accion !== "Asignar" && (
                      <Button
                        onClick={() => {
                          accion(d);
                        }}
                      >
                        {texto_accion}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
